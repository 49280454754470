.staffConsole {
  font-family: 'Cormorant', serif;
  font-weight: bold;
  padding: 3rem 0;
  width: 100%;
  overflow-x: hidden !important;
}

.staffConsole th {
  font-weight: bolder;
  font-size: 1.8rem;
}
.staffConsole td {
  font-weight: bolder;
  font-size: 1.3rem;
}
