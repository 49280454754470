.playVedio-con {
  width: 100%;
  height: 84vh;
  margin-top: 6rem;
}

.play-vedio {
  height: 400px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-vedio video {
  height: 500px;
  border-radius: 8px;
}
