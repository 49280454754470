.top-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 3rem;
}

.navbar-con {
  position: sticky !important;
  top: 0rem !important;
  z-index: 999;
  padding: 0 !important;
  font-family: 'Cormorant', serif;
  background-color: #fff;
}
.navbar-con.scrolling {
  background-color: #fff;
}
.navbar-con .hrline {
  width: 100%;
  background-color: #05757d;
  height: 0.06rem;
}
.navbar-logo {
  font-size: 1.5rem;
}

.navbar-logo img {
  height: 50px;
  width: 50px;
}
.navbar-items {
  cursor: pointer;
}
.navbar-box .nav-link {
  font-family: 'Cormorant', serif;
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  padding-top: 20px;
  transition: color 0.3s;
  color: #222222;
}

.navbar-box .navbar-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}
.nav-contact .contact-btn {
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 2px;
  background-color: #05757d !important;
}
.nav-contact .contact-btn:hover {
  background-color: #23afaf !important;
}

.navbar-con .nav .nav-link:hover,
.nav .nav-link:active {
  color: #00747c !important;
  transition: ease-in-out 0.3s;
}

.navbar-menu {
  list-style: none !important;
  display: flex;
  gap: 40px;
}
@media (min-width: 1024px) {
  .navbar-con .nav {
    width: 100%;
    color: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1023px) {
  .navbar-con {
    width: 100%;
    overflow-x: hidden !important;
  }

  .navbar-menu {
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #f80707;

    display: none;
    z-index: 2999;
    list-style: none !important;
  }
  .navbar-items {
    height: 3rem;
    list-style: none;
  }
  .navbar-menu .active {
    display: flex !important;
  }
  .navbar-con .nav {
    width: 100%;
    color: #222222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
  }

  .navbar-icon {
    display: block !important;
    padding: 0 0.6rem;
  }
  .top-nav {
    padding: 1rem;
  }
}
