.document_list {
  display: flex;
  align-items: flex-start !important;
  position: relative;
  top: 0;
  z-index: 0;
  font-family: 'Cormorant', serif;
  font-weight: bold;
}
.documet_cardshow {
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
}
.document_list img {
  height: 200px;
  width: 100%;
}

.btn-filter button {
  background-color: #05757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1.4rem !important;
  cursor: pointer;
  font-weight: bold;
}

.btn-filter button:hover {
  color: #fff;
  background-color: #0598a2 !important;
}

@media (min-width: 723px) and (max-width: 1025px) {
  .documet-card {
    padding-left: 10rem;
  }
}
.filter-com {
  display: block;
}

@media (max-width: 1023px) {
  .btn-filter button {
    width: 10rem;
    margin-bottom: 2rem;
  }
  .filter-com.open {
    display: block;
  }
  .filter-com.close {
    display: none;
  }
}

@media (min-width: 1024px) {
  .btn-filter {
    display: none;
  }
}
