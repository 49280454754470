.footer-con {
  width: 100%;
  padding-bottom: 2rem;
}
.footer-sect-column img {
  width: 104px;
  height: 145px;
}
.footer-tag {
  width: 248px;
  height: 40px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #52525b;
}
.footersec-link p a:hover,
.footersec-link p a:active {
  color: #026666 !important;
  text-decoration: underline;
}
.footersec-link h3 {
  width: 203px;
  text-align: center;
}
.footersec-link {
  height: auto;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 3rem;
  padding-top: 2rem;
}
.footersec-link h3 {
  width: 159px;
  height: 18px;
  font-family: Garamond;
  font-size: 18px;
  font-weight: 530;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #06382a;
  padding: 1.5rem 0;
}
.footersec-link a {
  width: 103px;
  height: 110px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  color: #52525b !important;
  text-align: left;
  text-decoration: none;
}
.footersec-copyright {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.footersec-copyright p {
  width: 374px;
  height: 22px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #71717a;
}

.footer-social {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-social .insta-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d8;
  cursor: pointer;
}
.footer-social .insta-icon a {
  text-decoration: none;
  color: #090914;
  margin-bottom: 5px;
}
.footer-social .insta-icon a:hover,
.footer-social .insta-icon a:active {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d8;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #026666;
  color: white;
  margin-bottom: 0px !important;
}

@media (max-width: 535px) {
  .footer-sec {
    padding: 0 !important;
  }
  .footer-sect-column img {
    width: 80px;
    height: 100px;
  }
  .footersec-copyright p {
    width: 374px;
    height: 22px;
    text-align: center;
  }
  .footer-tag {
    width: 248px;
    height: 20px;
    text-align: center;
  }
  .footer-social {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 11rem;
  }
  .footersec-link h3 {
    width: 203px;
    text-align: center;
  }
  .footersec-link {
    height: auto;
    width: 280px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    right: 3rem;
    padding-top: 1rem;
  }
  .footersec-link h3 {
    width: 123px;
    height: 18px;
    font-family: Garamond;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #06382a;
    padding: 1.5rem 0;
  }
  .footersec-link a {
    width: 80px;
    height: 80px;
    font-family: Rubik;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: #52525b;
    text-align: left;
    text-decoration: none;
  }
}
