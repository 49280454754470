/* CourseDetailsPage.css */

.course-details-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

h2 {
  color: #333;
}

.course-video {
  width: 100%;
  height: auto;
}

p {
  /* line-height: 1.6; */
  color: #555;
}

/* Add more styles based on your specific requirements */
