.services {
  font-family: 'Cormorant', serif;
  overflow-x: hidden !important;
}
.services h2 {
  text-align: center;
  font-weight: bolder;
  padding-bottom: 1rem;
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: white;
  word-spacing: 1.5px;
}
.services .card {
  margin-bottom: 1rem;
  width: 22rem;
  /* border-radius: 15px; */
  border: 1px solid #25afaf;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.services .card-header {
  /* border-top-left-radius: 15px; */
  /* border-top-right-radius: 15px; */
  background-color: #fff;
  border-bottom: 1px solid #25afaf;
}
.services .card-header h3 {
  font-weight: bold;
  font-size: 1.8rem;
  letter-spacing: 1px;
  word-spacing: 1.5px;
}
.services-content p {
  font-size: 1.2rem;
}
.services-content span {
  padding-left: 0.8rem;
}

.services .card {
}
