/* Image styling */
.rectangle {
  width: 100%;
  height: auto;
  display: block;
}

/* Title styling */
.text-wrapper {
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  text-align: center;
  background-color: #007bff;
  color: #fff;
  border-radius: 8px 8px 0 0;
}

/* Image modal content styling */
.image-modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e0e0e0;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

/* my style box */
.Project_card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start !important;
  /* background-color: #007bff; */
}
.Project_card .card {
  width: 21rem;
  background-color: rgb(249, 249, 249);
  box-shadow: rgba(249, 248, 248, 0.24) 0px 3px 8px;
  margin-bottom: 1.5rem;
  gap: 20;
}
.projectdetails_card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.Project_card .card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.projectdetail_h2 {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 2px;
  color: #484747;
}
.projectdetails_card h6 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  word-spacing: 2px;
  padding: 0 !important;
  color: #484747;
}
.projectdetails_icon {
  font-size: 18px;
  font-weight: bold;
  padding-top: 0.8rem;
}

/* Icon styling */
.icon-search-normal,
.icon-printer,
.icon-eye {
  cursor: pointer;
  width: 28px;
  height: 28px;
  transition: transform 0.2s;
}

/* Hover effect for icons */
.icon-search-normal:hover,
.icon-printer:hover,
.icon-eye {
  transform: scale(1.1);
}

/* Image modal styling  */
.image-modal {
  z-index: 2;
  background-color: #f1efef;
}

.projectdetails_icon {
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
