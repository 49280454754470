.logo-con {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-left: 8rem;
  background-color: #fff;
  box-shadow: 0px 25px 30px 0px #0000000d;
  overflow-x: hidden !important;
}

.logo {
  width: 142.75px;
  height: 41.03px;
  display: flex;
  margin: 0 2.5rem 2rem 2.5rem;
}

.logo img {
  width: 32.61px;
  height: 37.61px;
  position: relative;
  object-fit: contain;
  top: 1.71px;
}
.logo p {
  position: relative;
  width: 97.34px;
  height: 18.67px;
  top: 6.68px;
  left: 10px;
  font-weight: 700;
  font-size: 20px;
  color: black;
}

@media (max-width: 535px) {
  .logo-con {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-top: 2rem;
    padding-left: 0rem;
  }
  .logo {
    width: 122.75px;
    height: 41.03px;
    display: flex;
    position: relative;
    right: 1rem;
    /* margin: 0 2.5rem 2rem 2.5rem; */
  }
}

@media (min-width: 535px) and (max-width: 1024px) {
  .logo-con {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-top: 3rem;
    padding-left: 0rem;
  }
  .logo {
    width: 122.75px;
    height: 41.03px;
    display: flex;
    position: relative;
    right: 1rem;
    /* margin: 0 2.5rem 2rem 2.5rem; */
  }
}
