/* DocumentDetails.css */

.loading {
  text-align: center;
  font-size: 20px;
  color: #777;
  margin-top: 50px;
}

.error {
  text-align: center;
  font-size: 20px;
  color: #ff0000;
  margin-top: 50px;
}
.document-details-modal {
  margin-bottom: 3rem;
  font-family: 'Cormorant', serif;
}

.document-details-modal h2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: bolder;
  letter-spacing: 1px;
  word-spacing: 2px;
  color: black;
  font-size: 2rem;
  text-align: center;
  font-family: 'Cormorant', serif;
}

.document_table th {
  font-size: 1.7rem;
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
}
.document_table table {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

tbody {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.document_table td {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  letter-spacing: 1px;
}
/* .document_slider {
  width: 60vw;
  height: 67vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 30px !important;
} */

.document_slider {
  width: 100%;
  height: 67vh;
  max-width: 60vw;
  max-height: 67vh;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 30px !important;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

/* .document_slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.swiper-slide {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 30px !important;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #05757d;
}

/* Navigation (Arrows) Styles */
.swiper-button-next,
.swiper-button-prev {
  color: #05757d;
  background-color: transparent;
  z-index: 77;
  /* margin-top: 1rem; */
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 40px;
}
@media (min-width: 1024px) {
  .document_table {
    margin-left: 4rem;
  }
}
