.staff-login-box {
  font-family: 'Cormorant', serif;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.staff-login-contents {
  padding: 1rem 1.5rem;
}

.staff-login-text {
  color: rgb(73, 72, 72);
  padding: 0 !important;
  letter-spacing: 2px;
  font-size: bold;
  font-size: 1.8rem !important;
}

.staff-login-line {
  height: 1px;
  background-color: #e8e7e7;
  width: 100%;
}

.staff-login-body {
  padding: 1.5rem;
}

.staff-login-box input {
  margin-bottom: 1rem;
}

.staff-login-btn {
  width: 100%;
  padding-left: 20px;
  font-size: 25px !important;
  letter-spacing: 2px;
  word-spacing: 1px;
  font-weight: bold;
  background-color: #008b96;
  transition: 0.3s ease-in-out;
  color: white;
  text-align: left;
}

.staff-login-btn:hover {
  color: white;
  background-color: #00727a;
}

@media (max-width: 600px) {
  .staff-login-box {
    font-family: 'Cormorant', serif;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .staff-login-box label,
  .staff-login-box input {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
