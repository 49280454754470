.home-container {
  width: 100%;
  height: 80vh;
  /* overflow-x: hidden !important; */
}

.home-content {
  width: 100%;
  display: flex;
  background: #f9fafb;
  justify-content: center;
}
.leftside-content {
  width: 555px;
  position: relative;
  top: 90px;
}
.Archi h2 {
  font-family: Garamond;
  font-size: 110px;
  font-weight: 400;
  line-height: 91px;
  letter-spacing: 0.6px;
  text-align: left;
  color: #000;
}
.home-para {
  width: 431px;
  height: 97px;
  top: 405px;
  left: 100px;
}
.home-para p {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.5;
  text-align: left;
  color: #4c4a46;
}
.home-para .jcloudtext {
  color: #000;
  font-size: 20px;
  font-weight: 800;
}

.home-content button {
  position: relative;
  top: 30px;
  width: 210px;
  height: 73px;
  border-radius: 5px;
  font-family: Moneta;
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
  border: 1px solid #026666;
  background-color: #026666;
}
.home-content button a {
  color: #fff;
  text-decoration: none;
}
.home-content button:hover {
  position: relative;
  top: 30px;
  width: 210px;
  height: 73px;
  border-radius: 5px;
  font-family: Moneta;
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
  border: 1px solid #024f4f;
  background-color: #024f4f;
}
.home-content button a:hover {
  color: #fff;
  text-decoration: none;
}
.rightside-content {
  position: relative;
  width: 50%;
}
.home-img img {
  width: 603.78px;
  height: 588.34px;
  position: relative;
  top: 20px;
  object-fit: contain;
}
.circle-img a img {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 18rem;
  left: 5rem;
  object-fit: contain;
  border-radius: 50%;
  box-shadow: 0px 12.980769157409668px 25.961538314819336px 0px #00000017;
}

.unique-style {
  position: absolute;
  top: 60px;
  left: 32rem;
  width: 107px;
  height: 62px;
  color: #4c4a46;
}
.unique-style h3,
.Project-Finished h3,
.Happy-Customers h3 {
  font-family: Garamond Narrow;
  font-size: 33px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0 !important;
}
.unique-style p,
.Happy-Customers p,
.Project-Finished p {
  width: 136px;
  height: 34px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #4c4a46;
  padding-top: 0;
}
.Project-Finished {
  position: absolute;
  width: 136px;
  height: 62px;
  top: 160px;
  left: 35rem;
}

.Happy-Customers {
  position: absolute;
  top: 270px;
  left: 34rem;
  width: 150px;
  height: 82px;
}

@media (max-width: 1024px) {
  .home-container {
    width: 100%;
    height: auto;
  }
  .home-content {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
  }
  .leftside-content {
    width: 100%;
    position: relative;
    top: 20px;
    padding: 2rem;
    display: block;
    margin: 0 auto;
    height: auto;
  }
  .Archi h2 {
    width: 100%;
    font-size: 60px;
    line-height: 60px;
  }
  .home-para {
    width: 431px;
    height: 97px;
  }
  .home-para p {
    width: 80%;
    line-height: 26px;
    font-family: Rubik;
    font-size: 18px;
  }

  .home-content button {
    width: 180px;
    height: 55px;
    margin-top: 2rem;
  }
  .rightside-content {
    position: relative;
    width: 50%;
  }
  .home-img img {
    width: 280.78px;
    height: 388.34px;
    position: relative;
    top: 0px;
    object-fit: contain;
  }
  .circle-img img {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 11rem;
    left: 3rem;
    object-fit: contain;
  }

  .unique-style {
    position: absolute;
    top: 50px;
    left: 15rem;
    width: 107px;
    height: 62px;
    color: #4c4a46;
  }
  .unique-style h3,
  .Project-Finished h3,
  .Happy-Customers h3 {
    font-family: Garamond Narrow;
    font-size: 26px;
    line-height: 25px;
  }

  .Project-Finished {
    position: absolute;
    width: 136px;
    height: 40px;
    top: 130px;
    left: 15rem;
  }

  .Happy-Customers {
    position: absolute;
    top: 200px;
    left: 15rem;
    width: 150px;
    height: 82px;
  }
}
