.Residential {
  width: 100%;
  height: 45vh;
  overflow-x: hidden !important;
}
.residential-con {
  width: 100%;
  padding: 0 2rem;
}
.Residential h1 {
  width: 416px;
  height: 156px;
  position: relative;
  top: 7rem;
  font-family: Garamond;
  font-size: 85px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: left;
}
.Residential .verital-line {
  position: relative;
  top: 7rem;
  height: 156px;
  width: 1px;
  margin-left: 1.5rem;
  background-color: black;
}
.residetial-content {
  display: flex;
}
.Residential p {
  height: 97px;
  position: relative;
  max-width: 740px;
  top: 7rem;
  right: 2rem;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4c4a46;
}
.residential-img {
  margin-right: 2rem;
}
.residential-img h2 {
  width: 483px;
  height: 31px;
  font-family: Garamond;
  font-size: 33px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #242424;
  margin-top: 15px;
  padding-left: 1rem;
  margin-bottom: 0 !important;
}
.residential-img p {
  width: 305px;
  height: 28px;
  font-family: Rubik;
  font-size: 18px;
  padding-left: 1rem;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #4c4a46;
  margin-bottom: 1rem !important;
}
.residential-img img {
  width: 600px;
  height: 600px;
  object-fit: contain;
}

@media (max-width: 535px) {
  .residential-con {
    width: 100%;
    padding: 0 1rem !important;
  }
  .Residential {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 76vh;
  }
  .Residential h1 {
    width: 416px;
    height: 156px;
    position: relative;
    top: 0rem;
    line-height: 65px;
    text-align: center;
    font-size: 55px;
  }
  .Residential .verital-line {
    display: none;
  }
  .residential-img h2 {
    width: 373px;
    height: 26px;
    font-size: 28px;
    font-weight: 400;
    line-height: 31px;
    margin-top: 0px;

    margin-bottom: 0 !important;
  }
  .Residential p {
    height: 150px;
    position: relative;
    max-width: 870px;
    top: 1rem;
    line-height: 28px;
  }
  .residential-img {
    margin-right: 0rem;
  }
  .residential-img img {
    width: 300px;
    height: 400px;
    object-fit: contain;
    margin-right: 0 !important;
  }
}

@media (min-width: 536px) and (max-width: 1024px) {
  .residential-con {
    width: 100%;
    padding: 0 1rem !important;
  }
  .Residential {
    height: 36vh;
  }
  .Residential h1 {
    width: 416px;
    height: 156px;
    position: relative;
    top: 4rem;
    line-height: 65px;
    font-size: 55px;
  }
  .Residential .verital-line {
    position: relative;
    top: 7rem;
    height: 156px;
    width: 1px;
    margin-left: 1.5rem;
    background-color: black;
  }
  .residential-img h2 {
    width: 373px;
    height: 31px;
    font-size: 28px;
    font-weight: 400;
    line-height: 31px;
    margin-top: 15px;
    margin-bottom: 0 !important;
  }
  .Residential p {
    height: 250px;
    position: relative;
    max-width: 870px;
    top: 3rem;
    padding-left: 3rem;
    line-height: 28px;
  }
  .residential-img {
    margin-right: 0rem;
  }
  .residential-img img {
    width: 300px;
    height: 400px;
    object-fit: contain;
    margin-right: 0 !important;
  }
}
