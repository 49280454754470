.task-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  font-family: 'Cormorant', serif;
  font-weight: bolder;
  height: 75rem;
}
.task-form label {
  font-size: 1.3rem;
  font-family: 'Cormorant', serif;
  font-weight: bold;
  padding: 3px 0;
}
.task-form input,
.task-form select {
  font-size: 1.3rem;
  font-family: 'Cormorant', serif;
  margin-bottom: 9px;
}
.task-form .category {
  margin-bottom: 9px;
}
.task-form textarea {
  resize: none;
  margin-bottom: 9px;
}
.task-form .form-group {
  margin: 1rem 0;
}
.task-form button {
  background-color: #008b96;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 25px !important;
  cursor: pointer;
  width: 8rem;
  letter-spacing: 2px;
  border-radius: 4px;
  float: left;
  margin-top: 1.4rem !important;
}

.task-form button:hover {
  color: white;
  background-color: #00727a;
}
