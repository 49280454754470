.blog-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  font-family: 'Cormorant', serif;
  font-weight: bolder;
}

.blog h2 {
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: bold;
  word-spacing: 2px;
}
.blog-form label {
  font-size: 1.3rem;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.blog-form input,
.blog-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  resize: none;
}

.blog-form button {
  background-color: #05757d;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 22px !important;
  cursor: pointer;
  width: 8rem;
  letter-spacing: 2px;
  border-radius: 4px;
  margin-top: 0.8rem;
}

.blog-form button:hover {
  color: white;
  background-color: #00727a;
}
