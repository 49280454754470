.form-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.course-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  font-weight: bold;
}
.course-form button {
  padding: 12px 24px;
  background-color: #0074d9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.course-form button:hover {
  background-color: #0056b3;
}
.course-form {
  width: 50rem;
  margin: 2rem auto;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  font-weight: bold;
}

.course-form h2 {
  font-size: 1.8rem;
  letter-spacing: 1px;
  font-weight: bold;
  word-spacing: 2px;
}
.course-form label {
  font-size: 1.3rem;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.course-form input,
.course-form select,
.course-form textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.2rem;
  letter-spacing: 1px;
  resize: none;
}

.course-form button {
  background-color: #008b96;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 25px !important;
  cursor: pointer;
  width: 8rem;
  margin-top: 1rem;
  letter-spacing: 2px;
  border-radius: 4px;
}

.course-form button:hover {
  color: white;
  background-color: #00727a;
}
