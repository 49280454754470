.dashboard-container {
  background-color: #fafafa;
  font-family: 'Cormorant', serif;
  display: flex;
}

.dashboard-left {
  font-family: 'Cormorant', serif;
  background-color: #04595f;
  width: 18rem;
}

.dashboard-left .nav-item {
  width: 20rem;
  padding: 0 !important;
  text-align: left !important;
}

.dashboard-left .nav-item .nav-link {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  word-spacing: 2px;
  text-transform: capitalize;
  cursor: pointer;
  transition: color ease-in-out 0.5s;
}

.dashboard-left .nav-item .nav-link:hover {
  color: beige;
}

.admin-right-box {
  width: 76rem;
  font-family: 'Cormorant', serif;
}

@media (max-width: 578px) {
  .dashboard-left {
    width: 15%;
    padding-top: 0.3rem;
    margin-left: 0 !important;
  }
  .dashboard-left .nav-item {
    width: 15%;
    position: relative;
    right: 0.5rem;
  }
  .dashboard-name {
    display: none;
  }
  .admin-right-box {
    width: 128%;
    font-family: 'Cormorant', serif;
  }
  .welcome-message {
    width: 100%;
    padding: 1rem;
  }
}

@media (min-width: 578px) and (max-width: 1024px) {
  .dashboard-left {
    width: 12%;
    padding-top: 1rem;
    margin-left: 0 !important;
  }
  .dashboard-left .nav-item {
    width: 12%;
  }
  .dashboard-name {
    display: none;
  }
  .admin-right-box {
    width: 100%;
  }
  .welcome-message {
    width: 100%;
    padding: 1rem;
  }
}
