@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

body {
  font-family: 'Cormorant', serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
