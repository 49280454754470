.TermCondition-con {
  width: 100%;
  height: 100%;
}
.TermCondition {
  width: 100%;
  padding: 2rem 3rem;
}

.TermCondition h2,
.TermCondition h3 {
  font-family: Garamond;
}
.TermCondition h2 {
  font-size: 38px;
}
.TermCondition h3 {
  font-size: 30px;
}
.TermCondition p,
.TermCondition li {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  text-align: justify;
  color: #555;
}
