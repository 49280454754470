.serch-certificate {
  font-family: 'Cormorant', serif;
  font-weight: bolder;
  width: 100%;
}
.serch-certificate h2 {
  font-weight: bolder;
  font-size: 2.5rem;
}

@media (max-width: 1025px) {
  .serch-certificate h2 {
    font-weight: bolder;
    font-size: 1.8rem;
  }
}
