/* my style */

.loading-indicator {
  color: #333;
  font-size: 1.5rem;
}

.text-login {
  color: rgb(73, 72, 72);
  padding: 0 !important;
  letter-spacing: 1px;
  font-size: bold;
  font-size: 1.9rem !important;
}

.success {
  color: #4caf50;
  font-size: 20px;
}

.error {
  color: #ff5722;
  font-size: 20px;
}
.login-box {
  font-family: 'Cormorant', serif;
  height: 100%;
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.register-box input {
  margin: 0.7rem 0;
}
.forgot-password-link {
  text-align: right;
  font-size: 20px;
  letter-spacing: 1px;
  word-spacing: 3px;
  padding-bottom: 5px;
}

.register-btn {
  width: 100%;
  padding-left: 20px;
  font-size: 25px !important;
  letter-spacing: 2px;
  word-spacing: 1px;
  font-weight: bold;
  background-color: #008b96;
  transition: 0.3s ease-in-out;
  color: white;
  text-align: left;
  margin-top: 1.5rem;
}
.register-btn:hover,
.register-btn:active {
  color: white;
  background-color: #00727a;
}
.signup {
  text-align: right !important;
  font-size: 1.2rem;
}
.allready-account {
  font-size: 20px !important;
  letter-spacing: 1px;
  word-spacing: 1px;
}
@media (max-width: 600px) {
  .login-container {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}
.login-box {
  font-family: 'Cormorant', serif;
  height: 100%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}
.register-box a {
  float: right;
}

.register-box label,
.register-box input {
  font-size: 1.2rem;
  font-weight: bold;
}
