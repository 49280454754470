/* CertificateViewer.css */

.certificate-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  font-family: 'Cormorant', serif;
  font-weight: bolder;
}

.certificate-table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;
}
.certificate-container table {
  width: 100%;
  border-radius: 10px !important;
}
.certificate-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  font-size: 1.2rem;
  font-weight: bolder;
}
.certificate-table .table-label {
  font-weight: bolder;
}
.certificate-table td:first-child {
  font-weight: bold;
}

.loading {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: #777;
}
