.choose-con {
  width: 100%;
  height: auto;
  padding: 4rem 3rem;
}
.choose-content h1 {
  width: 440px;
  height: 160px;
  font-family: Garamond;
  font-size: 85px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: left;
}
.choose-content .verital-line {
  height: 156px;
  width: 1px;
  margin-left: 0.5rem;
  background-color: black;
}
.choose-content {
  display: flex;
}
.choose p {
  width: 705px;
  height: 120px;
  right: 2rem;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.6px;
  text-align: left;
  color: #4c4a46;
}
.choose-bootom {
  width: 100%;
  padding-top: 3rem;
}
.choose-bootom .drafting {
  width: 100%;
  height: 120px;
  padding-top: 2rem;
}
.choose-bootom .drafting-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choose-bootom .horizotal-line {
  width: 582px;
  height: 1.5px;
  background-color: #000;
}
.choose-bootom p {
  width: 250px;
  height: 28px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-align: right;
  color: #4c4a46;
}
.choose-bootom h5 {
  width: 150px;
  height: 28px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #4c4a46;
}
.darfting-img img {
  width: 601px;
  height: 438px;
  margin-left: 10px;
}

@media (max-width: 535px) {
  .choose-con {
    width: 100%;
    height: auto;
    padding: 2rem 1rem;
  }
  .choose-content h1 {
    width: 500px;
    height: 70px;
    font-size: 35px;
    font-weight: 400;
    line-height: 60px;
    text-align: left;
  }
  .choose-content .verital-line {
    display: none;
  }
  .choose p {
    width: 325px;
    height: 120px;
  }
  .choose-bootom {
    width: 100%;
    padding-top: 9rem;
  }
  .choose-bootom .drafting {
    width: 100%;
    height: 90px;
    padding-top: 2rem;
  }
  .choose-bootom .drafting-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .choose-bootom .horizotal-line {
    width: 322px;
    height: 1.5px;
    margin-top: 1rem;
    background-color: #000;
  }
  .choose-bootom p {
    width: 305px;
    height: 28px;
    font-family: Rubik;
    font-size: 17px;
    letter-spacing: 0px;
  }
  .choose-bootom h5 {
    width: 170px;
  }
  .darfting-img img {
    width: 320px;
    height: 338px;
    object-fit: cover;
    margin-left: 0px;
    margin-top: 2rem;
  }
}

@media (min-width: 536px) and (max-width: 1024px) {
  .choose-con {
    width: 100%;
    height: auto;
    padding: 4rem 1rem;
  }
  .choose-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .choose-content h1 {
    width: 400px !important;
    height: 160px;
    font-size: 55px;
    line-height: 60px;
  }
  .choose-content .verital-line {
    display: none;
  }
  .choose p {
    width: 455px;
    height: 120px;
    margin-left: 2rem;
  }
  .choose-bootom h5 {
    width: 220px;
  }
  .choose-bootom {
    width: 100%;
    padding-top: 9rem;
  }
  .choose-bootom .drafting {
    width: 100%;
    height: 90px;
    padding-top: 2rem;
  }
  .choose-bootom .drafting-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .choose-bootom .horizotal-line {
    width: 322px;
    height: 1.5px;
    margin-top: 1rem;
    background-color: #000;
  }
  .choose-bootom p {
    width: 305px;
    height: 28px;
    font-family: Rubik;
    font-size: 17px;
    letter-spacing: 0px;
  }
  .darfting-img img {
    width: 320px;
    height: 338px;
    object-fit: cover;
    margin-left: 0px;
    margin-top: 2rem;
  }
}
