.Home-design {
  background: #f8fafb;
  width: 100%;
  height: auto;
}
.home-center-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-center-content h1 {
  width: 842px;
  height: 255px;
  position: relative;
  top: 4.8rem;
  font-family: Garamond;
  font-size: 85px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #242424;
}
.home-center-content p {
  width: 544px;
  height: 97px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #4c4a46;
}

.home-bottom-content .card {
  width: 400px;
  height: 400px;
  border: none !important;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem 1rem 2rem;
}
.home-bottom-content img {
  width: 181px;
  height: 104.2px;
  object-fit: contain;
}
.home-bottom-content .card-body-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem !important;
}
.home-bottom-content .card-body-section h2 {
  width: 300px;
  height: 31px;
  font-family: Garamond;
  font-size: 33px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 2px;
  text-align: center;
  color: #242424;
}
.home-bottom-content .card-body-section p {
  width: 332px;
  height: 110px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #4c4a46;
}

.homedesign-btn {
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homedesign-btn button {
  width: 210px;
  height: 73px;
  border-radius: 5px;
  font-family: Moneta;
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
  border: 1px solid #026666;
  background-color: #026666;
}
.homedesign-btn button a {
  color: #fff;
  text-decoration: none;
}
.homedesign-btn button:hover {
  width: 210px;
  height: 73px;
  border-radius: 5px;
  font-family: Moneta;
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #fff;
  border: 1px solid #024f4f;
  background-color: #024f4f;
}
.homedesign-btn button a:hover {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 535px) {
  .home-center-content h1 {
    width: 400px;
    height: 165px;
    position: relative;
    top: 3rem;
    font-family: Garamond;
    font-size: 40px;
    font-weight: 400;
    padding: 0 1rem;
    line-height: 55px;
  }
  .home-center-content p {
    width: 344px;
    height: 97px;
  }
  .home-bottom-content .card {
    width: 320px;
    height: 440px;
    margin: 1rem;
    padding: 3rem 2rem 1rem 2rem;
  }

  .home-bottom-content .card-body-section p {
    width: 282px;
    height: 110px;
  }
}

@media (min-width: 535px) and (max-width: 1024px) {
  .home-center-content h1 {
    width: 600px;
    height: 165px;
    position: relative;
    top: 3rem;
    font-size: 60px;
    font-weight: 400;
    line-height: 55px;
  }
  .home-center-content p {
    width: 444px;
    height: 97px;
    font-size: 18px;
  }
  .home-bottom-content .card {
    width: 320px;
    height: 440px;
    margin: 1rem;
    padding: 3rem 2rem 1rem 2rem;
  }

  .home-bottom-content .card-body-section p {
    width: 282px;
    height: 110px;
  }
}
