.serchresult {
  width: 100%;
  font-family: 'Cormorant', serif;
  font-weight: bolder;
}
.serch-grid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.blog-card {
  margin: 2rem 0;
  width: 80%;
}
.serchresult p {
  font-size: 1.5rem;
}

@media (max-width: 578px) {
  .serch-con {
    width: 100%;
  }
  .serch-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog-card {
    margin: 0.6rem 0;
    width: 100%;
  }
}

@media (min-width: 578px) and (max-width: 1024px) {
  .serch-con {
    width: 100%;
  }
  .admin-right-box .serch-box {
    width: 135%;
  }
  .admin-right-box .serch-grid {
    width: 135%;
  }

  .serch-box {
    width: 100%;
  }
  .serch-box input {
    width: 100%;
  }
  .serch-grid {
    width: 100%;
  }
  .serch-card {
    width: 50%;
    margin: 0rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog-card {
    margin: 1rem 0;
    width: 80%;
  }
}
