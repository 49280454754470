/* AdminPanel.css */

/* my style */
.admin-left-con {
  font-family: 'Cormorant', serif;
  width: 18rem;
  background-color: #04595f;
  /* background-color: #fcdbc8; */
}
.admin-left-con .nav-item a {
  text-decoration: none;
  cursor: pointer;
  color: white;
  letter-spacing: 1.2px;
  word-spacing: 2px;
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: capitalize;
}

.admin-left-con .nav-item a:hover {
  transition: ease-in-out 0.5s;
}
.admin-left-con .admin-logo {
  width: 80px !important;
  height: 70px;
  /* background-color: #ccc; */
  margin-bottom: 30px;
  padding-top: 10px;
  margin-left: 5px;
  border-radius: 10px;
}
.admin-left-con .logout {
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
}
.admin-left-con .nav-item {
  padding: 0 !important;
  width: 20rem;
  text-align: left !important;
  padding: 0 !important;
}
.admin-right-box {
  font-family: 'Cormorant', serif;
  width: 60rem;
  /* background-color: black; */
}
.welcome-message {
  font-size: 1.8rem;
  text-align: center;
  color: #73757b;
  font-weight: bold;
  padding: 4rem;
  letter-spacing: 1px;
  word-spacing: 2px;
}

@media (max-width: 578px) {
  .admin-left-con {
    width: 18%;

    margin-left: 0 !important;
  }
  .admin-nav li {
    width: 18% !important;
    position: relative;
    right: 0.6rem;
  }
  .admin-left-con .logout {
    width: 20% !important;
    position: absolute;
    bottom: 0;
  }
  .admin-name {
    display: none;
  }
  .admin-right-box {
    width: 120%;
  }
}

@media (min-width: 578px) and (max-width: 1024px) {
  .admin-left-con {
    width: 12%;
    margin-left: 0 !important;
  }
  .admin-nav li {
    width: 12% !important;
  }
  .admin-left-con .logout {
    width: 20% !important;
    position: absolute;
    bottom: 0;
  }
  .admin-name {
    display: none;
  }
  .admin-right-box {
    width: 135%;
  }
}
