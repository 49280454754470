/* Courses.css */

.courses-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.filter-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.course-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.course-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.course-video {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course-details {
  padding: 15px;
}

.course-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.course-tags {
  color: #888;
  margin-bottom: 5px;
}

.course-description {
  color: #555;
}
