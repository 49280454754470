.our-projects-con {
  width: 100%;
  height: auto;
}
.our-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 770px;
  flex-direction: column;
  border-radius: 10px;
  background: #f8fafb;
  position: relative;
  margin-bottom: 4rem;
}
.project-vedio {
  height: 500px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  /* background-color: #026666; */
}
.project-vedio video {
  height: 100%;
  border-radius: 8px;
}

.project-content h1 {
  width: 604px;
  height: 80px;
  font-family: Garamond;
  font-size: 85px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #242424;
}
.project-content p {
  width: 621px;
  height: 57px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #242424;
}
.project-dots {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notclick {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 0.5rem;
  background: #d4d4d4;
}
.play-btn img {
  position: absolute;
  top: 27rem;
  left: 37rem;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.click {
  width: 36px;
  height: 36px;
  margin: 0 0.5rem;
  background: #026666;
  border-radius: 50%;
  border: 0.27px solid #fff6f6;
}

@media (max-width: 535px) {
  .our-projects-con {
    width: 80%;
    height: auto;
    margin: 0 2rem;
  }
  .our-projects {
    width: 100%;
    height: 630px;
    position: relative;
    margin-left: 1rem;
  }
  .our-projects img {
    width: 320px;
    height: 320px;
    margin-top: 2rem;
    object-fit: cover;
    margin-right: 2rem;
  }
  .project-content h1 {
    width: 450px !important;
    height: 60px;
    font-size: 44px;
    line-height: 60px;
    text-align: center;
  }
  .project-content p {
    width: 421px;
    height: 87px;
    padding: 0 2rem;
  }
  .project-dots {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notclick {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 0 0.5rem;
    background: #d4d4d4;
  }
  .play-btn img {
    position: absolute;
    top: 20rem;
    left: 6rem;
    width: 60px;
    height: 60px;
    object-fit: cover;
    z-index: 88;
    border-radius: 50%;
  }
  .click {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
  }
}

@media (min-width: 535px) and (max-width: 1024px) {
  .our-projects-con {
    width: 100%;
    height: auto;
    margin: 0 2rem;
  }
  .our-projects {
    width: 100%;
    height: 930px;
    margin-top: 2rem;
  }
  .our-projects img {
    width: 720px;
    height: 720px;
    margin-top: 2rem;
    object-fit: cover;
    margin-right: 3rem;
  }
  .project-content h1 {
    width: 650px !important;
    height: 60px;
    font-size: 70px;
    line-height: 60px;
    text-align: center;
  }
  .project-content p {
    width: 721px;
    height: 87px;
    padding: 0 2rem;
  }
  .project-dots {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notclick {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin: 0 0.5rem;
    background: #d4d4d4;
  }
  .play-btn img {
    position: absolute;
    top: 30rem;
    left: 16rem;
    width: 80px;
    height: 80px;
    object-fit: cover;
    z-index: 88;
    border-radius: 50%;
  }
  .click {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
  }
}
