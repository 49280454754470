.serch-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-family: 'Cormorant', serif;
  font-weight: bolder;
}
.serch-con {
  width: 100%;
}
.serch-box input {
  height: 2.5rem;
  border: 2px solid #05757d;
  font-size: 1.4rem;
  width: 100%;
}
.serch-box label {
  font-size: 1.4rem;
}
.btn-serch {
  width: 8rem;
  height: 2.5rem;
  background-color: #05757d;
  color: white;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  font-size: 1.2rem;
  font-weight: bolder;
  letter-spacing: 1.5px;
  margin-top: 2.5rem;
}

.btn-serch:hover,
.btn-serch:active {
  background-color: #23afaf !important;
  color: white;
  transition: ease-in-out 0.3s;
}

@media (max-width: 578px) {
  .serch-con {
    width: 100%;
    overflow-x: hidden;
  }
  .serch-box {
    width: 90%;
    padding: 0;
    height: 6rem;
  }
  .serch-box input {
    height: 2rem;
    width: 93%;
    border: 2px solid #05757d;
    font-size: 1rem;
  }
  .serch-box label {
    font-size: 1rem;
  }
  .btn-serch {
    width: 6rem;
    height: 2rem;
    background-color: #05757d;
    color: white;
    margin-left: 0rem;
    margin-top: 2rem;
    font-size: 1rem;
  }
}

@media (min-width: 578px) and (max-width: 1024px) {
  .serch-con {
    width: 100%;
  }
  .serch-box {
    width: 100%;
  }
  .serch-box input {
    width: 100%;
  }
  .btn-serch {
    margin-top: 2rem;
  }
}
