.privacy-con {
  width: 100%;
  height: 100%;
}
.privacy {
  width: 100%;
  padding: 2rem 3rem;
}
.privacy h1,
.privacy h2,
.privacy h3,
.privacy h4,
.privacy h5 {
  font-weight: 500;
  font-family: Garamond;
}
.privacy h2 {
  font-size: 38px;
}
.privacy h1 {
  font-size: 42px;
}
.privacy h3 {
  font-size: 34px;
}
.privacy h4 {
  font-size: 30px;
  font-weight: 500;
}
.privacy .privacy-highlight {
  font-family: Garamond;
  color: #000;
}
.privacy p,
.privacy li {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  text-align: justify;
  color: #555;
}
