.blog-page {
  padding: 1rem 0;
  font-family: 'Cormorant', serif;
}
.blog-page h2 {
  text-align: center;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: bolder;
  padding-bottom: 1rem;
}
.blog-serch {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}
.blog-serch input {
  width: 30rem;
  font-size: 1.4rem;
  font-size: bold;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  border: 2px solid #05757d;
}
.blog-serch input:focus {
  border: 2px solid #05757d;
}
.blog-serch button {
  margin-left: 1rem;
  padding: 6.2px 22px;
  color: white;
  letter-spacing: 1px;
  font-size: 1.3rem;
  word-spacing: 1.5px;
  font-size: bold;
  border-radius: 6px;
  background-color: #05757d;
}
.blog-serch button:hover {
  color: white;
  background-color: #0598a2;
}
.blog-page-card .card {
  width: 18rem;
  height: 22rem;
  gap: 10;
  border-radius: 10px;
  border: 1px solid #05757d;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.blog-page-card .card-header {
  background-color: #fff;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.blog-page-card a {
  text-decoration: none !important;
}
.card-header h3 {
  letter-spacing: 0.8px;
  word-wrap: 1px;
  font-size: 1.3rem;
  font-weight: 900;
}
.blog-page-card .card img {
  width:100%;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (max-width: 578px) {
  .blog-page {
    padding: 1rem 0;
    /* overflow-x: hidden !important; */
  }
  .blog-page h2 {
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: bolder;
    padding-bottom: 0.7rem;
  }
  .blog-serch {
    width: 100%;
  }
  .blog-serch input {
    width: 50%;
    font-size: 1.2rem;
    padding-left: 6px;
  }
  .blog-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog-serch button {
    margin: 5px;
    padding: 3px 10px;
    color: white;
    margin-top: 1rem;
    letter-spacing: 0px;
    font-size: 1rem;
    word-spacing: 1px;
    font-size: bold;
    border-radius: 6px;
    background-color: #05757d;
    position: relative;
    bottom: 0rem;
  }
}
.admin-right-box .blog-serch {
  width: 127%;
}
.admin-right-box .blog-page-card {
  width: 127%;
}

@media (min-width: 578px) and (max-width: 1024px) {
  .blog-page h2 {
    text-align: center;
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: bolder;
    padding-bottom: 0.7rem;
  }
  .admin-right-box .main-blog {
    width: 100%;
  }
  .admin-right-box .blog-serch {
    width: 100%;
  }

  .admin-right-box .blog-page-card {
    width: 135%;
  }
  .admin-right-box .blog-page .blog-serch {
    width: 135%;
  }
  .blog-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog-page .blog-serch {
    width: 100%;
  }
  .blog-serch input {
    width: 80%;
    font-size: 1.5rem;
    padding-left: 6px;
  }
  .blog-serch button {
    margin: 5px;
    padding: 3px 10px;
    color: white;
    margin-top: 1rem;
    letter-spacing: 0px;
    font-size: 1.2rem;
    word-spacing: 1px;
    font-size: bold;
    border-radius: 6px;
    background-color: #05757d;
    position: relative;
    bottom: 0.4rem;
  }
}
