.certificate-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  font-family: 'Cormorant', serif;
  font-weight: bolder;
  height: 70rem;
}
.select-container {
  position: relative;
  width: 100%;
}
.certificate-form input,
.certificate-form label,
.certificate-form select {
  font-size: 1.3rem;
}
.select-button {
  width: 100%;
  padding: 8px;
  height: 2.9rem;
  font-size: 1.1rem;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 1rem;
  margin: 0;
  box-shadow: 0 2px 5px rgba(10, 80, 53, 0.1);
  display: none;
  background-color: #05757d;
  border-radius: 5px;
  z-index: 1;
  font-size: 1.1rem;
  color: white;
}

.select-option:hover {
  background-color: #3498db;
  color: #fff;
}

.select-options.active {
  display: block;
}
.certificate-form input,
.certificate-form select {
  width: 100%;
  color: black;
}
.certificate-form option {
  color: black !important;
  background-color: #fff;
}
.certificate-form option:hover {
  color: white !important;
}
.certificate-form button {
  background-color: #008b96;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 25px !important;
  cursor: pointer;
  width: 8rem;
  letter-spacing: 2px;
  border-radius: 4px;
  float: left;
  margin-top: 1.4rem !important;
}

.certificate-form button:hover {
  color: white;
  background-color: #00727a;
}
.certificate-form .select-icon {
  position: absolute;
  right: 0.7rem;
}
.specilized-btn {
  width: 100%;
  padding: 1rem 0.5rem;
  /* background-color: #05757d; */
  color: black;
}
.specilized-btn option {
  padding: 0.3rem 0;
  color: white;
  padding-left: 1rem;
}
.specilized-btn option:hover,
.specilized-btn option:active {
  background-color: #3498db;
  color: #fff;
  padding-left: 1rem;
}
.year-select {
  height: 3rem;
  border-radius: 3px;
}
